import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions/confirmDetailActions';
import * as selectedOfferActions from '../../actions/selectedOfferActions';
import * as serverMessageActions from '../../actions/serverMessageActions';
import { AvField } from 'availity-reactstrap-validation';
import { Container, Row, Col } from 'reactstrap';
import ServerMessage from './serverMessage';
import { scroller } from 'react-scroll';
import OtpDialog from '../dialogs/otpDialog';
import CreditCheckFailedDialog from '../dialogs/creditCheckFailedDialog';
import OfferConfirmPersonalDetailsAu from './offerConfirmPersonalDetailsAu';
import OfferConfirmPersonalDetailsUs from './offerConfirmPersonalDetailsUs';
import OfferConfirmDirectDebitDetailsAu from './offerConfirmDirectDebitDetailsAu';
import OfferConfirmTermsAndConditionsAu from './offerConfirmTermsAndConditionsAu';
import OfferConfirmTermsAndConditionsUs from './offerConfirmTermsAndConditionsUs';
import OfferConfirmHomeLoanDetailsAu from './offerConfirmHomeLoanDetailsAu';
import OfferConfirmInternetDetailsAu from './offerConfirmInternetDetailsAu';
import { implementationCodes } from '../../enums/implementationCodes';
import { billingTypes, occupancyTypes, submissionTypes } from '../../enums/submissionTypes';
import { detailStatuses } from '../../enums/detailStatuses';
import { directDebitTypes } from '../../enums/directDebitTypes';
import _debounce from 'lodash.debounce';
import { getSessionActivity } from '../../utilities/commonUtilities';

export class OfferConfirm extends Component {
  constructor(props) {
    super(props);

    const {
      title: userTitle,
      firstName: userFirstName,
      surname: userSurname,
      mobilePhoneNumberNational: userPhoneNumber
    } = this.props.user;

    let title = userTitle;
    let firstName = userFirstName;
    let surname = userSurname;

    // For bills submissions where account name is populated but user is not, use details from the bill.
    if (this.props.comparison.submissionType === submissionTypes.BILL && this.props.comparison.submission.accountName) {
      const {
        titleId: accountTitleId,
        firstName: accountFirstName,
        surname: accountSurname
      } = this.props.comparison.submission.accountName;

      if (!title && accountTitleId) title = accountTitleId;
      if (!firstName && accountFirstName) firstName = accountFirstName;
      if (!surname && accountSurname) surname = accountSurname;
    }

    const hasDirectDebitCreditCard =
      this.props.offer.productDetail && this.props.offer.productDetail.hasDirectDebitCreditCard;
    const hasDirectDebitBankAccount =
      this.props.offer.productDetail && this.props.offer.productDetail.hasDirectDebitBankAccount;

    const selectedDirectDebitType = hasDirectDebitCreditCard
      ? directDebitTypes.CREDIT_CARD.Code
      : hasDirectDebitBankAccount
      ? directDebitTypes.BANK_ACCOUNT.Code
      : directDebitTypes.NONE.Code;

    this.hasDirectDebit = hasDirectDebitCreditCard || hasDirectDebitBankAccount;

    const defaultBillingTypeCode =
      this.props.offer && this.props.offer.defaultBillingType
        ? this.props.offer.defaultBillingType
        : billingTypes.NONE.Code;

    const allowOrganiser =
      this.props.offer &&
      this.props.offer.allowOrganiser &&
      this.props.config &&
      this.props.config.settings &&
      this.props.config.settings.allowOrganiser;
    const billingTypeCode =
      !allowOrganiser && defaultBillingTypeCode === billingTypes.EMAIL_ORGANISER.Code
        ? billingTypes.EMAIL_PERSONAL.Code
        : defaultBillingTypeCode;

    this.state = {
      isOtpOpen: null,
      isCreditCheckFailedOpen: null,
      isCreditCheckFailedOpenStamp: null,
      confirmDetail: {
        title: this.props.confirmDetailStore.title ? this.props.confirmDetailStore.title : title,
        firstName: this.props.confirmDetailStore.firstName ? this.props.confirmDetailStore.firstName : firstName,
        surname: this.props.confirmDetailStore.surname ? this.props.confirmDetailStore.surname : surname,
        emailAddress: this.props.user.emailAddress,
        phoneNumber: this.props.confirmDetailStore.phoneNumber
          ? this.props.confirmDetailStore.phoneNumber
          : userPhoneNumber,
        dateOfBirth: null,
        verifyMethod: null,
        verifyId: null,
        verifyIdExpiry: null,
        ssn: null,
        hasConcession: false,
        lifeSupport: true,
        solarFeedIn: false,
        termsAndConditions: false,
        concessionType: null,
        concessionTypeId: null,
        concessionTypeIdIssued: null,
        concessionTypeIdExpiry: null,
        medicalCooling: false,
        multipleSclerosis: false,
        concessionConsent: false,
        creditCheckConsent: false,
        primaryResidence: true,
        premiseType: null,
        passportCountry: this.props.config.country.alpha3,
        driversLicenceStateOfIssue: null,
        medicareIrn: null,
        medicareCardType: null,
        clientData1: null,
        membershipId: null,
        installStandard: false,
        installDate: null,
        otp: null,
        preferredLanguage: 'English',
        supplyAddress:
          this.props.comparison.submissionType === submissionTypes.ESTIMATE
            ? this.props.comparison.submission.propertyAddress
            : null, // initialize with submission property address; it may potentially get updated by the user.
        siteAccessIssues: null,
        siteAccessIssuesDetail: null,
        siteHazardIssues: null,
        siteHazardIssuesDetail: null,
        worksInProgress: null,
        worksInProgressDetail: null,
        powerOn:
          this.props.comparison.submissionType === submissionTypes.ESTIMATE &&
          this.props.comparison.occupancyType === occupancyTypes.NEW.id
            ? 'false'
            : 'true',
        accessConsent: null,
        existingAccount: 'false',
        keepExistingNumber: 'false',
        specificDeliveryDate: 'false',
        billingType: billingTypeCode,
        deliveryType: billingTypes.ADDRESS_CONNECTION.Code,
        directDebitType: selectedDirectDebitType,
        hasHomePhoneTransferAuthority: false,
        employeeHasCreditCard:
          this.props.offer.productDetail && this.props.offer.productDetail.showEmployeeHasCreditCard ? false : null,
        conditions: []
      }
    };

    this.implementationCode = this.props.comparison.implementationCode;
  }

  onSubmit = () => {
    this.props.submitForm();
  };

  onSendOtp = async () => {
    const accessToken =
      this.props.isAuthenticated && this.props.comparison.submissionId ? await this.props.getTokenSilently() : null;
    const sessionActivity = getSessionActivity(this.props.session);

    this.props.selectedOfferActions.sendOtp(
      this.props.comparison,
      this.props.offer,
      this.state.confirmDetail,
      accessToken,
      this.props.comparison.submissionId,
      sessionActivity
    );
  };

  getEmptyConditions = () => {
    var conditions = [];

    if (
      this.props.offer &&
      this.props.offer.productDetail &&
      this.props.offer.productDetail.consentConditions &&
      this.props.offer.productDetail.consentConditions.length > 0
    ) {
      this.props.offer.productDetail.consentConditions.forEach(condition => {
        var conditionAccept = { conditionId: condition.id, accepted: false, type: condition.type };

        conditions.push(conditionAccept);
      });
    }

    return conditions;
  };

  getInitialAddonSelections = (addons, allowMultipleSelections) => {
    const selections = addons.filter(addon => addon.isSelected).map(addon => addon.id);

    if (selections && selections.length > 0) {
      return allowMultipleSelections ? selections : selections[0];
    } else return null;
  };

  componentDidMount = () => {
    var initialConditions = this.getEmptyConditions();
    var newConfirmDetail;
    if (this.props.offer.twoStepConfirm && this.props.confirmDetailStore.entryStarted) {
      newConfirmDetail = { ...this.props.confirmDetailStore, conditions: initialConditions };
    } else {
      newConfirmDetail = { ...this.state.confirmDetail, conditions: initialConditions };
    }

    var productDetail = this.props.offer.productDetail;
    if (productDetail) {
      if (productDetail.homeLine && productDetail.homeLine.length > 0)
        newConfirmDetail['homeLine'] = this.getInitialAddonSelections(productDetail.homeLine, false);

      if (productDetail.modems && productDetail.modems.length > 0)
        newConfirmDetail['modem'] = this.getInitialAddonSelections(productDetail.modems, false);

      if (productDetail.otherAddons && productDetail.otherAddons.length > 0)
        newConfirmDetail['otherAddons'] = this.getInitialAddonSelections(productDetail.otherAddons, true);
    }

    this.setConfirmDetail(newConfirmDetail);

    if (this.state.isOtpOpen !== this.props.offer.requireOtp) this.setOtpOpen(this.props.offer.requireOtp);
  };

  componentDidUpdate = () => {
    if (this.state.isOtpOpen !== this.props.offer.requireOtp) this.setOtpOpen(this.props.offer.requireOtp);

    if (
      // Credit check failed = 1, Upfront payment required = 2
      (this.props.offer.detailsStatus === detailStatuses.CREDIT_CHECK_FAILED ||
        this.props.offer.detailsStatus === detailStatuses.UPFRONT_PAYMENT_REQUIRED) &&
      this.state.isCreditCheckFailedOpenStamp !== this.props.offer.detailsStatusStamp
    )
      this.setState({
        isCreditCheckFailedOpen: true,
        isCreditCheckFailedOpenStamp: this.props.offer.detailsStatusStamp
      });
  };

  onChangeGeneric = (e, target) => {
    var newConfirmDetail = { ...this.state.confirmDetail, ...target, entryStarted: true };

    this.setState({ confirmDetail: newConfirmDetail }, () => this.setConfirmDetailStore(newConfirmDetail));
  };

  setConfirmDetail = newConfirmDetail => {
    this.setState({ confirmDetail: newConfirmDetail }, () => {
      this.setConfirmDetailStore(newConfirmDetail);
    });
  };

  setConfirmDetailStore = _debounce(
    newConfirmDetail => {
      this.props.actions.setConfirmDetail(newConfirmDetail);
    },
    250,
    { leading: false }
  );

  onOtpCancel = () => {
    if (this.state.isOtpOpen) {
      this.props.selectedOfferActions.dispatchSelectedOfferSuccess({ ...this.props.offer, requireOtp: false });

      this.setState({ confirmDetail: { ...this.state.confirmDetail, otp: '' } }, () =>
        this.props.actions.setConfirmDetail(this.state.confirmDetail)
      );
    }
  };

  onCreditCheckFailedCancel = () => {
    if (this.state.isCreditCheckFailedOpen) {
      this.setState({ isCreditCheckFailedOpen: false });
    }
  };

  setOtpOpen = isOpen => {
    this.setState({ isOtpOpen: isOpen });
  };

  getLeadin = () => {
    switch (this.implementationCode) {
      case implementationCodes.HOME_LOAN.AU_DEFAULT:
        return "We're about to pass these details to an expert in our home loans team";
      default:
        return "We need some basic information and then we'll get started straight away.";
    }
  };

  render() {
    if (this.props.serverMessage.scrollTo) {
      setTimeout(() => {
        scroller.scrollTo('server-message', { duration: 800, delay: 0, smooth: 'easeInOutQuart', offset: -20 });
        this.props.serverMessageActions.setServerMessage({ scrollTo: false });
      }, 100);
    }

    return (
      <Container>
        {(!this.props.offer.twoStepConfirm || this.props.step === 'details') && (
          <>
            <div className="mt-2">
              <ServerMessage serverMessage={this.props.serverMessage} />
            </div>
            <Row>
              <Col xs="9" lg="10">
                <h2 className="qs-more-info-heading">Confirm your details</h2>
                <div className="qs-more-info-leadin">
                  <div>{this.getLeadin()}</div>

                  {this.implementationCode === implementationCodes.INTERNET.AU_DEFAULT &&
                    this.props.offer.isSummaryOfferOnly && (
                      <>
                        <div className="material-icons qs-offer-call-centre qs-mt-md">support_agent</div>
                        <div className="material-icons qs-offer-call-centre">phone_callback</div>
                        <div>
                          Internet offers and options like modems, routers and call packages can be confusing. Our call
                          center experts will call you to talk through your options and process your sale.
                        </div>
                      </>
                    )}

                  {this.props.offer.personalDetailsDisclaimer && (
                    <>
                      {' '}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: this.props.offer.personalDetailsDisclaimer
                        }}
                      ></span>
                    </>
                  )}
                </div>
              </Col>
              <Col xs="3" lg="2">
                <img
                  width="60px"
                  alt="Secure Shield"
                  src={`${this.props.config.applicationSettings.staticContentUrl}/images/util/ss.png`}
                />
              </Col>
            </Row>

            {this.props.offer.productDetail && this.props.offer.productDetail.applyNowContent && (
              <Row>
                <Col xs="12">
                  <div
                    className="sm-apply-now-content"
                    dangerouslySetInnerHTML={{
                      __html: this.props.offer.productDetail.applyNowContent
                    }}
                  />
                </Col>
              </Row>
            )}

            {this.props.config.allowClientData1 && (
              <>
                <Row className="qs-more-info-table-header">
                  <Col xs="12">{this.props.content.general.clientData1Heading}</Col>
                </Row>
                <Row>
                  <Col xs="12" md="6">
                    <AvField
                      bsSize="sm"
                      name="clientData1"
                      placeholder={this.props.content.general.clientData1Placeholder}
                      type="text"
                      value={this.state.confirmDetail.clientData1}
                      onChange={e => this.onChangeGeneric(e, { clientData1: e.target.value })}
                      validate={{
                        required: { value: true, errorMessage: this.props.content.general.clientData1ValidationError },
                        pattern: {
                          value: this.props.config.clientData1RegexValidation,
                          errorMessage: this.props.content.general.clientData1ValidationError
                        }
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}

            {
              {
                AuElectricityDefault: (
                  <>
                    <OfferConfirmPersonalDetailsAu
                      confirmDetail={this.state.confirmDetail}
                      offer={this.props.offer}
                      onChange={this.onChangeGeneric}
                      setConfirmDetail={this.setConfirmDetail}
                    />
                    {this.hasDirectDebit && (
                      <OfferConfirmDirectDebitDetailsAu
                        confirmDetail={this.state.confirmDetail}
                        offer={this.props.offer}
                        onChange={this.onChangeGeneric}
                        setConfirmDetail={this.setConfirmDetail}
                        showYourOrder={false}
                        showEncryptedPaymentDisclaimer={false}
                      />
                    )}
                  </>
                ),

                AuGasDefault: (
                  <>
                    <OfferConfirmPersonalDetailsAu
                      confirmDetail={this.state.confirmDetail}
                      offer={this.props.offer}
                      onChange={this.onChangeGeneric}
                    />
                    {this.hasDirectDebit && (
                      <OfferConfirmDirectDebitDetailsAu
                        confirmDetail={this.state.confirmDetail}
                        offer={this.props.offer}
                        onChange={this.onChangeGeneric}
                        setConfirmDetail={this.setConfirmDetail}
                        showYourOrder={false}
                        showEncryptedPaymentDisclaimer={false}
                      />
                    )}
                  </>
                ),

                AuInternetDefault: (
                  <>
                    <OfferConfirmInternetDetailsAu
                      confirmDetail={this.state.confirmDetail}
                      offer={this.props.offer}
                      onChange={this.onChangeGeneric}
                    />
                    {this.hasDirectDebit && (
                      <OfferConfirmDirectDebitDetailsAu
                        confirmDetail={this.state.confirmDetail}
                        offer={this.props.offer}
                        onChange={this.onChangeGeneric}
                        setConfirmDetail={this.setConfirmDetail}
                        showYourOrder={true}
                        showEncryptedPaymentDisclaimer={true}
                      />
                    )}
                  </>
                ),

                UsElectricityDefault: (
                  <OfferConfirmPersonalDetailsUs
                    confirmDetail={this.state.confirmDetail}
                    offer={this.props.offer}
                    implementationCode={this.implementationCode}
                    onChange={this.onChangeGeneric}
                  />
                ),

                AuHomeLoanDefault: (
                  <OfferConfirmHomeLoanDetailsAu
                    confirmDetail={this.state.confirmDetail}
                    offer={this.props.offer}
                    onChange={this.onChangeGeneric}
                    setConfirmDetail={this.setConfirmDetail}
                  />
                )
              }[this.implementationCode]
            }
          </>
        )}
        {(!this.props.offer.twoStepConfirm || this.props.step === 'terms') && (
          <>
            {this.props.offer.twoStepConfirm && <ServerMessage serverMessage={this.props.serverMessage} />}
            {
              {
                AuElectricityDefault: (
                  <OfferConfirmTermsAndConditionsAu
                    confirmDetail={this.state.confirmDetail}
                    setConfirmDetail={this.setConfirmDetail}
                    offer={this.props.offer}
                    onChange={this.onChangeGeneric}
                  />
                ),
                AuGasDefault: (
                  <OfferConfirmTermsAndConditionsAu
                    confirmDetail={this.state.confirmDetail}
                    setConfirmDetail={this.setConfirmDetail}
                    offer={this.props.offer}
                    onChange={this.onChangeGeneric}
                  />
                ),
                AuInternetDefault: (
                  <>
                    {this.props.offer.productDetail && this.props.offer.productDetail.inlineTermsAndConditions && (
                      <OfferConfirmTermsAndConditionsAu
                        confirmDetail={this.state.confirmDetail}
                        setConfirmDetail={this.setConfirmDetail}
                        offer={this.props.offer}
                        onChange={this.onChangeGeneric}
                      />
                    )}
                  </>
                ),
                UsElectricityDefault: (
                  <OfferConfirmTermsAndConditionsUs
                    confirmDetail={this.state.confirmDetail}
                    setConfirmDetail={this.setConfirmDetail}
                    offer={this.props.offer}
                    onChange={this.onChangeGeneric}
                  />
                ),
                AuHomeLoanDefault: (
                  <OfferConfirmTermsAndConditionsAu
                    confirmDetail={this.state.confirmDetail}
                    setConfirmDetail={this.setConfirmDetail}
                    offer={this.props.offer}
                    onChange={this.onChangeGeneric}
                  />
                )
              }[this.implementationCode]
            }
          </>
        )}
        <CreditCheckFailedDialog
          isOpen={this.state.isCreditCheckFailedOpen}
          onCancel={this.onCreditCheckFailedCancel}
        />
        <OtpDialog
          onOtpCancel={this.onOtpCancel}
          isOtpOpen={this.state.isOtpOpen}
          onChange={this.onChangeGeneric}
          onSubmit={this.onSubmit}
          buttonText={this.props.primaryButtonText}
          serverMessage={this.props.serverMessage}
          offer={this.props.offer}
          confirmDetail={this.state.confirmDetail}
          onSendOtp={this.onSendOtp}
          implementationCode={this.implementationCode}
          setConfirmDetail={this.setConfirmDetail}
        />
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    serverMessage: state.serverMessage,
    referenceData: state.config.referenceData,
    user: state.user,
    comparison: state.comparison,
    config: state.config,
    content: state.content,
    confirmDetailStore: state.confirmDetail,
    session: state.session
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    selectedOfferActions: bindActionCreators(selectedOfferActions, dispatch),
    serverMessageActions: bindActionCreators(serverMessageActions, dispatch)
  };
}

OfferConfirm.propTypes = {
  serverMessage: PropTypes.object.isRequired,
  referenceData: PropTypes.object.isRequired,
  comparison: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  offer: PropTypes.object.isRequired,
  submitForm: PropTypes.func.isRequired,
  primaryButtonText: PropTypes.string.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferConfirm);
