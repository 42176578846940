import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { scroller } from 'react-scroll';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { Container, Button } from 'reactstrap';
import _debounce from 'lodash.debounce';
import { states as statesEnum } from '../../enums/states.js';

import { useAuth0 } from '../../auth/auth0-Provider.js';

import ServerMessage from '../common/serverMessage.js';

import OtpDialog from '../dialogs/otpDialog.js';
import CreditCheckFailedDialog from '../dialogs/creditCheckFailedDialog.js';
import OfferConfirmPersonalDetailsUs from '../common/offerConfirmPersonalDetailsUs.js';
import OfferConfirmDirectDebitDetailsAu from '../common/offerConfirmDirectDebitDetailsAu.js';
import OfferConfirmTermsAndConditionsAu from '../common/offerConfirmTermsAndConditionsAu.js';
import OfferConfirmTermsAndConditionsUs from '../common/offerConfirmTermsAndConditionsUs.js';
import OfferConfirmHomeLoanDetailsAu from '../common/offerConfirmHomeLoanDetailsAu.js';

import { implementationCodes } from '../../enums/implementationCodes.js';
import { billingTypes, occupancyPurposes, occupancyTypes, submissionTypes } from '../../enums/submissionTypes.js';
import { detailStatuses } from '../../enums/detailStatuses.js';
import { directDebitTypes } from '../../enums/directDebitTypes.js';
import { scaleTypes } from '../../enums/scaleTypes.js';
import { sessionActivityTypes } from '../../enums/sessionActivityTypes.js';

import { dispatchSelectedOfferSuccess, sendOtp } from '../../actions/selectedOfferActions.js';
import { setConfirmDetail } from '../../actions/confirmDetailActions.js';
import { setServerMessage } from '../../actions/serverMessageActions.js';
import { addSessionActivity } from '../../actions/sessionActivityActions.js';

import { ControlledAccordion, useAccordionProvider } from '@szhsin/react-accordion';
import AccordionItem from '../Accordion/accordionItem.js';

import Business from '../common/business.js';
import BillDeliverySection from './sectionBillDelivery.js';
import MyDetailsSection from './sectionMyDetails.js';
import OtherSection from './sectionOther.js';
import IdVerificationSection from './sectionIdVerification.js';

import Summary from './summary.js';
import QuickTips from './quickTips.js';

import { getReferenceDataItem, getSessionActivity, isEmptyObject } from '../../utilities/commonUtilities.js';
import { getSubmissionDisplayAddress } from '../../utilities/addressUtilities.js';
import { idTypesEnum } from '../../enums/idTypesEnum.js';
import { billTypes } from '../../enums/billTypes.js';

import GeneralDialog from '../dialogs/generalDialog.js';
import PrivacyPolicyDialog from '../dialogs/privacyPolicyDialog.js';
import TermsAndConditionsDialog from '../dialogs/termsAndConditionsDialog.js';

import InternetQuestions from './internetQuestions.js';
import IdVerificationSectionInternet from './sectionIdVerificationInternet.js';
import QuickTipsOrganiser from './quickTipsOrganiser.js';
import QuickTipsDirectSwitch from './quickTipsDirectSwitch.js';

const OfferAccept = ({ offer, serviceType, onSubmit, stepType, primaryButtonText, isDirectSwitch }) => {
  const dispatch = useDispatch();
  const { getAccessToken, isUserAuthenticated } = useAuth0();

  const { uxSwitchVersion, allowOrganiser: allowOrganiserConfig } = useSelector(state => state.config.settings);

  const { titles, states, medicareCardTypes } = useSelector(state => state.config.referenceData);
  const { staticContentUrl } = useSelector(state => state.config.applicationSettings);
  const { comparison, confirmDetail: confirmDetailStore, config, user, serverMessage, session } = useSelector(
    state => state
  );
  const { submission, submissionId, submissionType, implementationCode } = useSelector(state => state.comparison);
  const { accountName, propertyAddress, occupancyPurpose } = useSelector(state => state.comparison.submission);
  const { clientData1Heading, clientData1Placeholder, clientData1ValidationError, organiserAgentName } = useSelector(
    state => state.content.general
  );

  const sessionActivity = getSessionActivity(session);

  const {
    productDetail,
    providerDisplayCode,
    providerLogoUrl,
    defaultBillingType,
    allowOrganiser,
    twoStepConfirm,
    requireOtp,
    detailsStatus,
    detailsStatusStamp,
    personalDetailsDisclaimer,
    indicator
  } = offer;

  const serviceTypeName = serviceType ? serviceType.Name.toLowerCase() : null;
  const isEnergy = serviceType === billTypes.ELECTRICITY || serviceType === billTypes.GAS;
  const hasDirectSwitch = productDetail.directSwitchLaunchUrl ? true : false;

  const hasDirectDebitCreditCard = productDetail && productDetail.hasDirectDebitCreditCard;
  const hasDirectDebitBankAccount = productDetail && productDetail.hasDirectDebitBankAccount;
  const hasDirectDebit = hasDirectDebitCreditCard || hasDirectDebitBankAccount;

  let hasIdVerification = false;
  let quickTipsContentRating = null;
  let quickTipsContentInteraction = null;

  const selectedDirectDebitType = hasDirectDebitCreditCard
    ? directDebitTypes.CREDIT_CARD.Code
    : hasDirectDebitBankAccount
    ? directDebitTypes.BANK_ACCOUNT.Code
    : directDebitTypes.NONE.Code;

  const billingTypeCode =
    (!allowOrganiser || !allowOrganiserConfig) && defaultBillingType === billingTypes.EMAIL_ORGANISER.Code
      ? billingTypes.EMAIL_PERSONAL.Code
      : defaultBillingType;

  // For bills submissions where account name is populated but user is not, use details from the bill.
  const bill = submissionType === submissionTypes.BILL && accountName ? accountName : {};
  const displayAddress = getSubmissionDisplayAddress(submissionType, submission);

  const detailData = {
    title: confirmDetailStore.title || user.title || bill.titleId,
    firstName: confirmDetailStore.firstName || user.firstName || bill.firstName,
    surname: confirmDetailStore.surname || user.surname || bill.surname,
    emailAddress: user.emailAddress,
    phoneNumber: confirmDetailStore.phoneNumber ? confirmDetailStore.phoneNumber : user.mobilePhoneNumberNational,
    dateOfBirth: null,
    verifyMethod: null,
    verifyId: null,
    verifyIdExpiry: null,
    ssn: null,
    hasConcession: false,
    lifeSupport: true,
    solarFeedIn: false,
    termsAndConditions: false,
    concessionType: null,
    concessionTypeId: null,
    concessionTypeIdIssued: null,
    concessionTypeIdExpiry: null,
    medicalCooling: false,
    multipleSclerosis: false,
    concessionConsent: false,
    creditCheckConsent: false,
    primaryResidence: true,
    premiseType: null,
    passportCountry: config.country.alpha3,
    driversLicenceStateOfIssue: null,
    medicareIrn: null,
    medicareCardType: null,
    clientData1: null,
    membershipId: null,
    installStandard: false,
    installDate: null,
    otp: null,
    preferredLanguage: 'English',
    supplyAddress: submissionType === submissionTypes.ESTIMATE ? propertyAddress : null, // initialize with submission property address; it may potentially get updated by the user.
    siteAccessIssues: null,
    siteAccessIssuesDetail: null,
    siteHazardIssues: null,
    siteHazardIssuesDetail: null,
    worksInProgress: null,
    worksInProgressDetail: null,
    powerOn:
      submissionType === submissionTypes.ESTIMATE && comparison.occupancyType === occupancyTypes.NEW.id
        ? 'false'
        : 'true',
    accessConsent: null,
    existingAccount: 'false',
    keepExistingNumber: 'false',
    specificDeliveryDate: 'false',
    billingType: billingTypeCode,
    deliveryType: billingTypes.ADDRESS_CONNECTION.Code,
    directDebitType: selectedDirectDebitType,
    hasHomePhoneTransferAuthority: false,
    marketingOptOut: false,
    employeeHasCreditCard: productDetail && productDetail.showEmployeeHasCreditCard ? false : null,
    conditions: []
  };

  const [isOtpOpen, setIsOtpOpen] = useState(null);
  const [isCreditCheckFailedOpen, setIsCreditCheckFailedOpen] = useState(null);
  const [isCreditCheckFailedOpenStamp, setIsCreditCheckFailedOpenStamp] = useState(null);
  const [detail, setDetail] = useState(detailData);
  const [summaryItems, setSummaryItems] = useState({ supplyAddress: displayAddress });

  const [quickTips, setQuickTips] = useState({});
  const [quickTipsDialogOpen, setQuickTipsDialogOpen] = useState(false);
  const [quickTipsDialogRating, setQuickTipsDialogRating] = useState();
  const [quickTipsDialogInteraction, setQuickTipsDialogInteraction] = useState();

  const [validationStateMyDetails, setValidationStateMyDetails] = useState({ hasOpened: true });
  const [validationStateIdVerification, setValidationStateIdVerification] = useState({});
  const [validationStateOther, setValidationStateOther] = useState({});
  const [validationStateBillDelivery, setValidationStateBillDelivery] = useState({});
  const [validationStateDirectDebit, setValidationStateDirectDebit] = useState({});
  const [validationStateTerms, setValidationStateTerms] = useState({});

  const onSendOtp = async () => {
    const accessToken = isUserAuthenticated() && submissionId ? await getAccessToken() : null;

    dispatch(sendOtp(sessionActivity, comparison, offer, detail, accessToken, submissionId));
  };

  const getEmptyConditions = () => {
    const conditions = [];

    if (productDetail && productDetail.consentConditions && productDetail.consentConditions.length > 0) {
      productDetail.consentConditions.forEach(condition => {
        conditions.push({ conditionId: condition.id, accepted: false, type: condition.type });
      });
    }

    return conditions;
  };

  const getInitialAddonSelections = (addons, allowMultipleSelections) => {
    const selections = addons.filter(addon => addon.isSelected).map(addon => addon.id);

    if (!selections || selections.length === 0) return null;

    return allowMultipleSelections ? selections : selections[0];
  };

  useEffect(() => {
    const conditions = getEmptyConditions();

    const mainDetail = twoStepConfirm && confirmDetailStore.entryStarted ? confirmDetailStore : detail;

    const model = { ...mainDetail, conditions };

    if (productDetail) {
      if (productDetail.homeLine && productDetail.homeLine.length > 0) {
        model.homeLine = getInitialAddonSelections(productDetail.homeLine, false);
      }

      if (productDetail.modems && productDetail.modems.length > 0) {
        model.modem = getInitialAddonSelections(productDetail.modems, false);
      }

      if (productDetail.otherAddons && productDetail.otherAddons.length > 0) {
        model.otherAddons = getInitialAddonSelections(productDetail.otherAddons, true);
      }
    }

    onConfirmDetailChanged(model);

    if (isOtpOpen !== requireOtp) {
      setIsOtpOpen(requireOtp);
    }
  }, []);

  useEffect(() => {
    if (isOtpOpen !== requireOtp) {
      setIsOtpOpen(requireOtp);
    }

    if (
      (detailsStatus === detailStatuses.CREDIT_CHECK_FAILED ||
        detailsStatus === detailStatuses.UPFRONT_PAYMENT_REQUIRED) &&
      isCreditCheckFailedOpenStamp !== detailsStatusStamp
    ) {
      setIsCreditCheckFailedOpen(true);
    }

    setIsCreditCheckFailedOpenStamp(detailsStatusStamp);
  }, [offer]);

  // can't we do this generically? May not need this here...
  useEffect(() => {
    if (serverMessage.scrollTo) {
      setTimeout(() => {
        scroller.scrollTo('server-message', { duration: 800, delay: 0, smooth: 'easeInOutQuart', offset: -20 });
        setServerMessage({ scrollTo: false });
      }, 100);
    }
  }, [serverMessage]);

  const onChange = (e, target) => {
    onConfirmDetailChanged({ ...detail, ...target, entryStarted: true });
  };

  const onConfirmDetailChanged = detail => {
    setDetail(detail);
    setConfirmDetailStore(detail);
  };

  const setConfirmDetailStore = _debounce(
    detail => {
      dispatch(setConfirmDetail(detail));
    },
    250,
    { leading: false }
  );

  const onOtpCancel = () => {
    if (isOtpOpen) {
      dispatch(dispatchSelectedOfferSuccess({ ...offer, requireOtp: false }));

      onConfirmDetailChanged({ ...detail, otp: '' });
    }
  };

  const onQuickTipInteraction = value => {
    setQuickTipsDialogInteraction(value);
    quickTipsContentInteraction = value;
  };

  const onQuickTipRated = value => {
    setQuickTipsDialogRating(value);
    quickTipsContentRating = value;
  };

  const getQuickTipValue = (dialogValue, contentValue) => {
    const value =
      dialogValue === true || contentValue === true
        ? true
        : dialogValue === false || contentValue === false
        ? false
        : dialogValue
        ? dialogValue
        : contentValue
        ? contentValue
        : undefined;

    return value;
  };

  const onQuickTipClosed = tag => {
    const liked = getQuickTipValue(quickTipsDialogRating, quickTipsContentRating);
    const interaction = getQuickTipValue(quickTipsDialogInteraction, quickTipsContentInteraction);

    dispatch(
      addSessionActivity({
        ...sessionActivity,
        data: {
          tag,
          liked,
          interaction
        },
        activityType: sessionActivityTypes.OFFER_ACCEPT_QUICK_TIP
      })
    );

    setQuickTipsDialogOpen(false);
    onQuickTipRated(null);
    onQuickTipInteraction(null);
  };

  const showDetails = !twoStepConfirm || stepType === 'details';
  const showTerms = !twoStepConfirm || stepType === 'terms';

  const accordionProviderValue = useAccordionProvider({
    allowMultiple: true,
    transition: true,
    transitionTimeout: 250
  });

  const { toggle, toggleAll } = accordionProviderValue;

  const auTerms = (
    <OfferConfirmTermsAndConditionsAu
      confirmDetail={detail}
      setConfirmDetail={onConfirmDetailChanged}
      offer={offer}
      onChange={onChange}
    />
  );

  const usTerms = (
    <OfferConfirmTermsAndConditionsUs
      confirmDetail={detail}
      setConfirmDetail={onConfirmDetailChanged}
      offer={offer}
      onChange={onChange}
    />
  );

  const getComponents = () => {
    const isBusiness = occupancyPurpose === occupancyPurposes.BUSINESS.Id;

    let myDetails = null;
    let businessDetails = null;
    let idVerificationDetails = null;
    let otherDetails = null;
    let billDeliveryDetails = null;
    let directDebitDetails = null;
    let termsDetails = null;

    let leadin =
      uxSwitchVersion === 1 ? "We need some basic information and then we'll get started straight away." : null;
    let callCentreDescription = null;

    switch (implementationCode) {
      case implementationCodes.ELECTRICITY.AU_DEFAULT:
      case implementationCodes.GAS.AU_DEFAULT:
        if (showDetails) {
          businessDetails = isBusiness ? <Business onChange={onChange} details={detail} /> : null;
          myDetails = <MyDetailsSection confirmDetail={detail} offer={offer} onChange={onChange} />;
          idVerificationDetails = (
            <IdVerificationSection
              confirmDetail={detail}
              offer={offer}
              onChange={onChange}
              onShowQuickTip={() => openQuickTipsDialog(sections.idVerification)}
            />
          );
          billDeliveryDetails = (
            <BillDeliverySection
              confirmDetail={detail}
              offer={offer}
              onChange={onChange}
              onShowQuickTip={() => openQuickTipsDialog(sections.billDelivery)}
            />
          );
          otherDetails = (
            <OtherSection
              formRef={sections.other.ref}
              confirmDetail={detail}
              offer={offer}
              onChange={onChange}
              showLifeSupport
              isEnergy
              setConfirmDetail={onConfirmDetailChanged}
            />
          );

          if (hasDirectDebit) {
            directDebitDetails = (
              <OfferConfirmDirectDebitDetailsAu
                confirmDetail={detail}
                offer={offer}
                onChange={onChange}
                setConfirmDetail={onConfirmDetailChanged}
                showYourOrder={false}
                showEncryptedPaymentDisclaimer={false}
              />
            );
          }
        }

        if (showTerms) {
          termsDetails = auTerms;
        }
        break;

      case implementationCodes.INTERNET.AU_DEFAULT:
        if (showDetails) {
          if (offer.isSummaryOfferOnly) {
            callCentreDescription = (
              <div className="call-centre-description">
                <div className="material-icons icon">support_agent</div>
                <div className="material-icons icon">phone_callback</div>
                <div>
                  Internet offers and options like modems, routers and call packages can be confusing. Our call center
                  experts will call you to talk through your options and process your sale.
                </div>
              </div>
            );
          }

          myDetails = <MyDetailsSection confirmDetail={detail} offer={offer} onChange={onChange} />;
          billDeliveryDetails = (
            <BillDeliverySection confirmDetail={detail} offer={offer} onChange={onChange} showMinimalManualFields />
          );
          otherDetails = (
            <>
              <InternetQuestions confirmDetail={detail} offer={offer} onChange={onChange} />
              <OtherSection
                formRef={sections.other.ref}
                confirmDetail={detail}
                offer={offer}
                onChange={onChange}
                setConfirmDetail={onConfirmDetailChanged}
              />
            </>
          );

          if (hasDirectDebit) {
            directDebitDetails = (
              <OfferConfirmDirectDebitDetailsAu
                confirmDetail={detail}
                offer={offer}
                onChange={onChange}
                setConfirmDetail={onConfirmDetailChanged}
                showYourOrder={true}
                showEncryptedPaymentDisclaimer={true}
              />
            );
          }

          if (productDetail.showVerifyId) {
            idVerificationDetails = (
              <IdVerificationSectionInternet confirmDetail={detail} offer={offer} onChange={onChange} />
            );
          }
        }

        if (showTerms && productDetail && productDetail.inlineTermsAndConditions) {
          termsDetails = auTerms;
        }
        break;

      case implementationCodes.ELECTRICITY.US_DEFAULT:
        if (showDetails) {
          myDetails = (
            <OfferConfirmPersonalDetailsUs
              confirmDetail={detail}
              offer={offer}
              implementationCode={implementationCode}
              onChange={onChange}
            />
          );
        }

        if (showTerms) {
          termsDetails = usTerms;
        }
        break;

      case implementationCodes.HOME_LOAN.AU_DEFAULT:
        leadin = "We're about to pass these details to an expert in our home loans team";

        if (showDetails) {
          myDetails = <OfferConfirmHomeLoanDetailsAu confirmDetail={detail} offer={offer} onChange={onChange} />;
        }

        if (showTerms) {
          termsDetails = auTerms;
        }
        break;

      default:
        break;
    }

    hasIdVerification = idVerificationDetails ? true : false;

    const myDetailsHeader = (
      <div className="my-details-header">
        <div>
          {uxSwitchVersion === 1 && <h2 className="qs-more-info-heading">Confirm your details</h2>}
          {leadin}
          {callCentreDescription}
          {personalDetailsDisclaimer && (
            <span
              dangerouslySetInnerHTML={{
                __html: personalDetailsDisclaimer
              }}
            ></span>
          )}
        </div>

        {uxSwitchVersion === 1 && (
          <img
            width="60px"
            alt="Secure Shield"
            src={`${config.applicationSettings.staticContentUrl}/images/util/ss.png`}
          />
        )}
      </div>
    );

    const hasApplyNowContent = productDetail && productDetail.applyNowContent;
    const applyNowContent = hasApplyNowContent ? (
      <div
        className="sm-apply-now-content"
        dangerouslySetInnerHTML={{
          __html: productDetail.applyNowContent
        }}
      />
    ) : null;

    const hasClientData = config.allowClientData1;
    const clientData = hasClientData ? (
      <>
        <div className="label">{clientData1Heading}</div>
        <div>
          <AvField
            bsSize="sm"
            name="clientData1"
            placeholder={clientData1Placeholder}
            type="text"
            value={detail.clientData1}
            onChange={e => onChange(e, { clientData1: e.target.value })}
            validate={{
              required: { value: true, errorMessage: clientData1ValidationError },
              pattern: {
                value: config.clientData1RegexValidation,
                errorMessage: clientData1ValidationError
              }
            }}
          />
        </div>
      </>
    ) : null;

    return (
      <ControlledAccordion providerValue={accordionProviderValue}>
        {myDetails && (
          <AccordionItem
            section={sections.myDetails}
            onShowQuickTips={openQuickTipsDialog}
            onToggle={onAccordionHeaderToggle}
            initialEntered
          >
            <AvForm
              onValidSubmit={() => onNext(sections.myDetails)}
              onInvalidSubmit={(e, errors) => onInvalidSubmit(errors, sections.myDetails)}
              ref={sections.myDetails.ref}
            >
              {myDetailsHeader}
              {applyNowContent}
              {clientData}
              {businessDetails}
              {myDetails}
              <NextButton section={sections.myDetails} />
              <OtpDialog
                onOtpCancel={onOtpCancel}
                isOtpOpen={isOtpOpen}
                onChange={onChange}
                onSubmit={onAcceptOffer}
                buttonText={primaryButtonText}
                serverMessage={serverMessage}
                offer={offer}
                confirmDetail={detail}
                onSendOtp={onSendOtp}
                implementationCode={implementationCode}
                setConfirmDetail={onConfirmDetailChanged}
              />
            </AvForm>
          </AccordionItem>
        )}

        {idVerificationDetails && (
          <AccordionItem
            section={sections.idVerification}
            onShowQuickTips={openQuickTipsDialog}
            onToggle={onAccordionHeaderToggle}
          >
            <AvForm
              onValidSubmit={() => onNext(sections.idVerification)}
              onInvalidSubmit={(e, errors) => onInvalidSubmit(errors, sections.idVerification)}
              ref={sections.idVerification.ref}
            >
              {idVerificationDetails}
              <NextButton section={sections.idVerification} />
            </AvForm>
          </AccordionItem>
        )}

        {otherDetails && (
          <AccordionItem section={sections.other} onToggle={onAccordionHeaderToggle}>
            <AvForm
              onValidSubmit={() => onNext(sections.other)}
              onInvalidSubmit={(e, errors) => onInvalidSubmit(errors, sections.other)}
              ref={sections.other.ref}
            >
              {otherDetails}
              <NextButton section={sections.other} />
            </AvForm>
          </AccordionItem>
        )}

        {billDeliveryDetails && (
          <AccordionItem
            section={sections.billDelivery}
            onShowQuickTips={isEnergy && allowOrganiserConfig ? openQuickTipsDialog : undefined}
            onToggle={onAccordionHeaderToggle}
          >
            <AvForm
              onValidSubmit={() => onNext(sections.billDelivery)}
              onInvalidSubmit={(e, errors) => onInvalidSubmit(errors, sections.billDelivery)}
              ref={sections.billDelivery.ref}
            >
              {billDeliveryDetails}
              <NextButton section={sections.billDelivery} />
            </AvForm>
          </AccordionItem>
        )}

        {directDebitDetails && (
          <AccordionItem
            section={sections.directDebit}
            onShowQuickTips={openQuickTipsDialog}
            onToggle={onAccordionHeaderToggle}
          >
            <AvForm
              onValidSubmit={() => onNext(sections.directDebit)}
              onInvalidSubmit={(e, errors) => onInvalidSubmit(errors, sections.directDebit)}
              ref={sections.directDebit.ref}
            >
              {directDebitDetails}
              <NextButton section={sections.directDebit} />
            </AvForm>
          </AccordionItem>
        )}

        {termsDetails && (
          <AccordionItem
            section={sections.terms}
            onShowQuickTips={hasDirectSwitch ? openQuickTipsDialog : undefined}
            onToggle={onAccordionHeaderToggle}
          >
            <AvForm
              onValidSubmit={() => onNext(sections.terms)}
              onInvalidSubmit={(e, errors) => onInvalidSubmit(errors, sections.terms)}
              ref={sections.terms.ref}
            >
              {termsDetails}
            </AvForm>
          </AccordionItem>
        )}
      </ControlledAccordion>
    );
  };

  const openQuickTipsDialog = section => {
    if (section.quickTips && !isEmptyObject(section.quickTips)) {
      setQuickTips({ ...section.quickTips, onRatingClick: onQuickTipRated, tag: section.tag });
      setQuickTipsDialogOpen(true);
      onQuickTipRated(null);
      onQuickTipInteraction(null);
    }
  };

  const onAccordionHeaderToggle = section => {
    section.setValidationState({ ...section.validationState, hasOpened: true });
  };

  const onNext = section => {
    let nextSection = null;
    let summary = null;

    section.setValidationState({ ...section.validationState, hasOpened: true, isValid: true });

    toggleAll(false);

    switch (section) {
      case sections.myDetails:
        const title = getReferenceDataItem(detail.title, titles);

        summary = {
          ...summaryItems,
          name: `${title ? title.description : null} ${detail.firstName} ${detail.surname}`,
          emailAddress: detail.emailAddress,
          phoneNumber: detail.phoneNumber,
          membershipId: detail.membershipId
        };

        nextSection =
          productDetail.showVerifyId || serviceType !== billTypes.INTERNET ? sections.idVerification : sections.other;
        break;

      case sections.idVerification:
        const id = parseInt(detail.verifyMethod);
        const primaryId = idTypesEnum.getById(id);
        let secondaryId;

        switch (primaryId) {
          case idTypesEnum.DRIVERS_LICENCE:
            secondaryId = getReferenceDataItem(detail.driversLicenceStateOfIssue, states);
            break;

          case idTypesEnum.PASSPORT:
            secondaryId = null;
            break;

          case idTypesEnum.MEDICARE:
            secondaryId = getReferenceDataItem(detail.medicareCardType, medicareCardTypes);
            break;

          case idTypesEnum.FOREIGN_PASSPORT:
            secondaryId = { description: detail.passportCountry };
            break;

          default:
            secondaryId = null;
        }

        summary = {
          ...summaryItems,
          idType: `${primaryId ? `${primaryId.Name}${secondaryId ? ` (${secondaryId.description})` : ''}` : ''}`
        };

        nextSection = sections.other;
        break;

      case sections.other:
        nextSection = sections.billDelivery;
        break;

      case sections.billDelivery:
        const billingType = billingTypes.getByCode(detail.billingType);
        let billingTypeName = null;

        switch (billingType) {
          case billingTypes.EMAIL_ORGANISER:
            billingTypeName = `My ${organiserAgentName}`;
            break;

          default:
            billingTypeName = billingType.Label;
        }

        summary = {
          ...summaryItems,
          billDelivery: billingTypeName
        };

        nextSection = hasDirectDebit ? sections.directDebit : sections.terms;
        break;

      case sections.directDebit:
        nextSection = sections.terms;
        break;

      default:
        nextSection = null;
    }

    if (nextSection !== null) {
      const id = `section_${sections.myDetails.id}`;

      toggle(nextSection.key, true);
      onAccordionHeaderToggle(nextSection);

      setTimeout(() => {
        scroller.scrollTo(id, { duration: 800, delay: 0, smooth: 'easeInOutQuart', offset: 0 });
      }, 350);
    }

    if (summary !== null) {
      setSummaryItems(summary);
    }

    dispatch(
      addSessionActivity({
        ...sessionActivity,
        data: {
          tag: section.tag
        },
        activityType: sessionActivityTypes.OFFER_ACCEPT_NEXT
      })
    );
  };

  const validationOptions = {
    requireVerification: false,
    requireOpened: true
  };

  const intVerifyMethod = detail.verifyMethod ? parseInt(detail.verifyMethod) : null;

  const getQuickTipDetail = (verifyMethod, driversLicenceStateOfIssue, medicareCardType) => {
    let front = null;
    let frontText = null;
    let back = null;
    let backText = null;
    let show = false;

    switch (verifyMethod) {
      case idTypesEnum.DRIVERS_LICENCE.Id:
        if (!driversLicenceStateOfIssue) show = false;
        else {
          show = true;
          front = `dl-${driversLicenceStateOfIssue}-front.svg`;
          frontText = 'Front of card';
          if (
            driversLicenceStateOfIssue !== statesEnum.AUSTRALIA.NSW.Id &&
            driversLicenceStateOfIssue !== statesEnum.AUSTRALIA.ACT.Id
          ) {
            back = `dl-${driversLicenceStateOfIssue}-back.svg`;
            backText = 'Back of card';
          }
        }
        break;
      case idTypesEnum.PASSPORT.Id:
        show = true;
        front = `australia-passport.svg`;
        frontText = 'Australian passport';
        break;

      case idTypesEnum.MEDICARE.Id:
        if (!medicareCardType) show = false;
        else {
          show = true;
          front = `medicare-${medicareCardType}-front.svg`;
          frontText = 'Front of card';
        }
        break;
      default:
        break;
    }

    return { show, front, frontText, back, backText };
  };

  const quickTipDetail = getQuickTipDetail(intVerifyMethod, detail.driversLicenceStateOfIssue, detail.medicareCardType);

  const sections = {
    myDetails: {
      id: 1,
      key: 'myDetailsSection',
      tag: 'my-details',
      label: 'My details',
      ref: useRef(null),
      validationOptions,
      validationState: validationStateMyDetails,
      setValidationState: setValidationStateMyDetails,
      quickTips: {
        heading: 'About your details',
        introduction: (
          <>
            <p>
              {providerDisplayCode} requires your contact details and ID verification information to complete your{' '}
              {serviceTypeName} application.
            </p>
            <p>
              We take your privacy seriously. We will never share your information with any third parties for any reason
              other than to provide our core services as outlined in our{' '}
              <TermsAndConditionsDialog buttonText="terms and conditions" /> and{' '}
              <PrivacyPolicyDialog buttonText="Privacy Policy" />.
            </p>
            <div className="footer-icon">
              <img
                className="icon"
                width="60px"
                alt="Secure Shield"
                src={`${config.applicationSettings.staticContentUrl}/images/util/ss.png`}
              />
            </div>
          </>
        )
      }
    },
    idVerification: {
      id: 2,
      key: 'idVerificationSection',
      tag: 'id-verification',
      label: 'ID verification',
      ref: useRef(null),
      validationOptions,
      validationState: validationStateIdVerification,
      setValidationState: setValidationStateIdVerification,
      quickTips: {
        heading: 'Find your ID details',
        introduction: (
          <>
            {quickTipDetail.show ? (
              <>
                <div className="sm-quicktip-detail-container">
                  {quickTipDetail.front && (
                    <div className="sm-quicktip-detail-item">
                      <img
                        src={`${staticContentUrl}/images/misc/${quickTipDetail.front}`}
                        alt={quickTipDetail.frontText}
                      />
                      {quickTipDetail.frontText && <p>{quickTipDetail.frontText}</p>}
                    </div>
                  )}
                  {quickTipDetail.back && (
                    <div className="sm-quicktip-detail-item">
                      <img
                        src={`${staticContentUrl}/images/misc/${quickTipDetail.back}`}
                        alt={quickTipDetail.backText}
                      />
                      {quickTipDetail.backText && <p>{quickTipDetail.backText}</p>}
                    </div>
                  )}
                </div>
              </>
            ) : (
              <p>
                Select your preferred ID verification method and your drivers licence state of issue or medicare card
                colour and click Quick Tips again to see where the required information is on your card / document.
              </p>
            )}

            <div className="heading" style={{ textAlign: 'center' }}>
              License not handy?
            </div>

            <p>
              Try searching for the word 'license' in the photos section of your smartphone for a previous photo you've
              taken.
            </p>
            <div className="license-search">
              <img src={`${staticContentUrl}/images/misc/photo-search.png`} alt="driver-license-search" />
            </div>
          </>
        )
      }
    },
    other: {
      id: 3,
      key: 'otherSection',
      tag: 'other',
      label: 'Other questions',
      ref: useRef(null),
      validationOptions,
      validationState: validationStateOther,
      setValidationState: setValidationStateOther
    },
    billDelivery: {
      id: 4,
      key: 'billDeliverySection',
      tag: 'bill-delivery',
      label: 'Bill delivery',
      ref: useRef(null),
      validationOptions,
      validationState: validationStateBillDelivery,
      setValidationState: setValidationStateBillDelivery,
      quickTips: {
        heading: null,
        introduction: (
          <QuickTipsOrganiser
            onClose={() => onQuickTipClosed(sections.billDelivery.tag)}
            onInteraction={value => onQuickTipInteraction(value)}
            providerDisplayCode={providerDisplayCode}
            providerLogoUrl={providerLogoUrl}
          />
        )
      }
    },
    directDebit: {
      id: 5,
      key: 'directDebitSection',
      tag: 'direct-debit',
      label: 'Direct debit',
      ref: useRef(null),
      validationOptions,
      validationState: validationStateDirectDebit,
      setValidationState: setValidationStateDirectDebit,
      quickTips: {
        heading: 'Setting up direct debit',
        introduction: (
          <>
            <p>Setting up direct debit takes just a few minutes</p>
            <p>
              Once you've switched, {providerDisplayCode} will send you a welcome pack explaining how you can set up
              direct debit online or over the phone if you prefer that option.
            </p>
            <div className="footer-icon">
              <img src={`${staticContentUrl}/images/misc/welcome-envelope.png`} alt="welcome-envelope" />
            </div>
          </>
        )
      }
    },
    terms: {
      id: 6,
      key: 'termsSection',
      tag: 'terms',
      label: 'Terms',
      ref: useRef(null),
      validationOptions: { ...validationOptions, requireVerification: false },
      validationState: validationStateTerms,
      setValidationState: setValidationStateTerms,
      quickTips: hasDirectSwitch
        ? {
            heading: 'Need a second opinion?',
            introduction: (
              <QuickTipsDirectSwitch
                serviceType={serviceType}
                providerDisplayCode={providerDisplayCode}
                directSwitchLaunchUrl={productDetail.directSwitchLaunchUrl}
                directSwitchTag={productDetail.directSwitchTag}
                onClose={() => onQuickTipClosed(sections.terms.tag)}
                onInteraction={value => onQuickTipInteraction(value)}
              />
            )
          }
        : null
    }
  };

  const AcceptButton = ({ scaleType }) => {
    return (
      <div className={`offer-buttons ${scaleType.className}`}>
        <Button className="sm-offer-button medium" onClick={onAcceptOffer}>
          {primaryButtonText}
        </Button>
      </div>
    );
  };

  const NextButton = ({ section }) => {
    const validationState = section.validationState;
    const validationOptions = section.validationOptions;

    const onClick = () => {
      section.setValidationState({ ...validationState, hasVerified: true });
      section.ref.current.submit();
    };

    const showValidation =
      validationOptions.requireVerification &&
      validationState.hasVerified === false &&
      validationState.isValid !== false;

    return (
      <>
        <div className="next-button-container">
          <div>
            <Button className="sm-button-primary medium wide" onClick={onClick}>
              Next
            </Button>
          </div>
          {showValidation && <div className="validation-message">Please review these questions and press Next</div>}
        </div>
      </>
    );
  };

  const onInvalidSubmit = (errors, section) => {
    if (errors) {
      setTimeout(() => {
        scroller.scrollTo(errors[0], { duration: 800, delay: 0, smooth: 'easeInOutQuart', offset: -30 });
      }, 250);
    }

    if (section) {
      section.setValidationState({ ...section.validationState, isValid: false });
    }
  };

  const containerClass = `animated fadeInUpSmall fast qs-offer qs-offer-${indicator}`;

  const validateSections = () => {
    let passed = true;
    const sectionsArray = Object.values(sections).map(section => ({ ...section }));

    sectionsArray.forEach(section => {
      const avForm = section.ref.current;

      if (!avForm) return;

      const sectionValid = !avForm.state || isEmptyObject(avForm.state.invalidInputs);

      if (!sectionValid) {
        avForm.handleSubmit();
        toggle(section.key, true);
        passed = false;
      }

      const validationState = section.validationState;
      const options = section.validationOptions;

      // If section is invalid it will auto open.
      const hasOpened = !options.requireOpened || !sectionValid || validationState.hasOpened === true;
      const hasVerified = !options.requireVerification || validationState.hasVerified === true;

      if (!hasOpened || !hasVerified) {
        toggle(section.key, true);
        passed = false;
      }

      section.setValidationState({
        ...validationState,
        isValid: sectionValid,
        hasOpened: hasOpened,
        hasVerified: hasVerified
      });
    });

    return passed;
  };

  const onAcceptOffer = () => {
    toggleAll(false);

    const isValid = validateSections();

    if (isValid) {
      onSubmit(offer, submissionId);
    }
  };

  return (
    <>
      <Container className={containerClass}>
        <div className="mt-2">
          <ServerMessage serverMessage={serverMessage} />
        </div>

        {uxSwitchVersion === 2 ? (
          <>
            <div className="section-container">
              <div className="left-section">
                {getComponents()}
                <AcceptButton scaleType={scaleTypes.lgUp} />
              </div>

              <div className="right-section">
                <Summary
                  hasIdVerification={hasIdVerification}
                  serviceType={serviceType}
                  offer={offer}
                  items={summaryItems}
                  onShowQuickTips={openQuickTipsDialog}
                />
              </div>
            </div>

            <AcceptButton scaleType={scaleTypes.mdDown} />
          </>
        ) : (
          getComponents()
        )}
      </Container>
      <CreditCheckFailedDialog isOpen={isCreditCheckFailedOpen} onCancel={() => setIsCreditCheckFailedOpen(false)} />
      <GeneralDialog key="quick-tips" dialogOpen={quickTipsDialogOpen} showFooter={false} showCloseIcon={false}>
        <QuickTips tip={quickTips} onClose={onQuickTipClosed} onRatingClick={onQuickTipRated} />
      </GeneralDialog>
    </>
  );
};

export default OfferAccept;
